/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html,
body,
#root {
  height: 100%;
}
.colorWeak {
  -webkit-filter: invert(80%);
          filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  list-style: none;
}
#fl-chat_launcher-wrapper {
  z-index: 9999;
}
/*
.ant-upload-picture-card-wrapper{
  width:100%;
}

.ant-upload-list-item {
  background: #f3f3f3;
}
*/
#root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Noto Sans TC', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.antd-pro-layouts-user-layout-title {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Noto Sans TC', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}
.spacer {
  width: 20px;
  display: inline-block;
}
/* upload list fix */
.ant-upload-list {
  float: left;
}
/* tab fix */
.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.hide-card-body .ant-card-body {
  display: none;
}
.picker-card-body .ant-card-body {
  background: #EEEEEE;
  padding: 1px;
}
.picker-card-body:hover,
.picker-card-body:hover .ant-card-body {
  background: #4d7cea !important;
}
.picker-card-body:hover .ant-card-body {
  background: unset!important;
}
.picker-card-body:hover .ant-card-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.picker-card-body:hover .ant-card-head-title {
  color: #FFF;
  font-weight: bold;
}
.changed .ant-switch-inner {
  color: #1fa51f;
}
.changed,
.changed .ant-select-selection,
.changed input {
  background-color: #c8ffca !important;
}
.frame_seleted {
  background: #158eff29 !important;
}
.title_extra {
  font-size: 14px;
  margin-left: 20px;
}
.emoji_disabled {
  background: #f5f5f5 !important;
  color: #b8b8b8 !important;
}
@media screen and (max-width: 600px) {
  .ant-pro-basicLayout-content {
    margin: 0!important;
  }
}
.ant-pro-basicLayout-content > div,
.ant-pro-basicLayout-content .ant-pro-page-header-wrap-children-content {
  margin: 0!important;
}
@media screen and (max-width: 576px) {
  .ant-card-body {
    padding: 5px 5px!important;
  }
}
@media screen and (max-width: 576px) {
  .ant-table-zoom {
    display: block;
    width: 100%;
    overflow-x: auto;
    margin: 0px;
    padding: 0px;
    border: unset !important;
  }
  .ant-table-zoom .ant-table-content > .ant-table-body {
    margin: 0px 0px!important;
  }
  .ant-table-zoom .ant-table-selection-column {
    flex: 0 0;
  }
  .ant-table-zoom .h-1 {
    display: none;
  }
  .ant-table-zoom .f-1 {
    display: none;
  }
  .ant-table-zoom-thead {
    display: none;
  }
  .ant-table-zoom-thead > tr,
  .ant-table-zoom-tbody > tr {
    display: flex!important;
    flex-wrap: wrap;
    margin: 5px 0px;
    border: 1px solid #999;
  }
  .ant-table-zoom-thead > tr th,
  .ant-table-zoom-tbody > tr th,
  .ant-table-zoom-thead > tr td,
  .ant-table-zoom-tbody > tr td {
    white-space: pre;
  }
  .ant-table-zoom-thead > tr th > span,
  .ant-table-zoom-tbody > tr th > span,
  .ant-table-zoom-thead > tr td > span,
  .ant-table-zoom-tbody > tr td > span {
    display: block;
  }
  .ant-table-zoom-thead > tr th:first-of-type,
  .ant-table-zoom-tbody > tr th:first-of-type,
  .ant-table-zoom-thead > tr td:first-of-type,
  .ant-table-zoom-tbody > tr td:first-of-type {
    padding-top: 1rem;
  }
  .ant-table-zoom-thead > tr th:last-of-type,
  .ant-table-zoom-tbody > tr th:last-of-type,
  .ant-table-zoom-thead > tr td:last-of-type,
  .ant-table-zoom-tbody > tr td:last-of-type {
    padding-bottom: 1rem;
  }
  .ant-table-zoom-thead > tr > th,
  .ant-table-zoom-tbody > tr > th,
  .ant-table-zoom-thead > tr > td,
  .ant-table-zoom-tbody > tr > td {
    display: block;
    width: auto !important;
    border: none;
    padding: 0 1rem;
    flex: 1 1;
  }
  .ant-table-zoom-thead > tr > th:last-child,
  .ant-table-zoom-tbody > tr > th:last-child,
  .ant-table-zoom-thead > tr > td:last-child,
  .ant-table-zoom-tbody > tr > td:last-child {
    border-bottom: 1px solid #eee;
  }
}
.ant-select-selection-selected-value img {
  height: 24px;
}
.ant-table-body {
  overflow-x: auto;
}
